<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>KEYWORDS REPORTS</strong></h5>
    <a-row :gutter="[16,4]">
      <a-col :xl="6" :md="12" :xs="24">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Feeds"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="filteredColumns.selectedPubfeeds.Values"
        >
          <a-select-option v-for="obj in pubfeed.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <div>
          <a-input
            allowClear
            v-model="filteredColumns.searchKeyword.Contains"
            placeholder="Search Keyword"
          />
        </div>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <div style="display: flex; justify-content: space-between">
          <a-range-picker
            :defaultValue="this.ranges.Yesterday"
            @change="setDateRange"
            :ranges="ranges"
            style="width: 70%"
          />
          <a-button type="primary" class="ml-2" @click="setFilters" style="width: 30%">
            Apply
          </a-button>
        </div>
      </a-col>
    </a-row>

    <b-row class="mt-2">
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect :xml="true" v-model="perPage" :storageKey="'xml-reports-keyword'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-reports-keyword-columns'" :options="fields.filter(ele => ele.hidden !== true)"></columnSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Main table element -->
    <b-table
      @sort-changed="sortingChanged"
      ref="table"
      show-empty
      :small="true"
      :bordered="true"
      :responsive="true"
      :striped="true"
      :hover="true"
      stacked="md"
      :items="getTableData"
      :fields="filteredFields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import keywordColumns from './columns/keywordFields'
import { getData } from '@/api/xml/keywords'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      reports: [],
      sortDirection: 'desc',
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      selectedDate: [moment().startOf('month'), moment().endOf('month')],
      filteredColumns: {
        selectedPubfeeds: {
          Column: 'PubfeedId',
          Values: [],
        },
        searchKeyword: {
          Column: 'Keyword',
          Values: [],
          Contains: '',
        },
      },
      filters: [],
      thisMonth: [moment().startOf('month'), moment().endOf('month')],
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      moment: moment,
      isBusy: false,
      tableData: [],
      fields: keywordColumns,
      currentPage: 1,
      perPage: 200,
      pageOptions: [200, 500, 1000],
      order: [{
        Column: 'NetClicks',
        Ord: 'DESC',
      }],
      total: 0,
    }
  },
  created() {
    this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
    this.setDateRange(this.ranges.Yesterday)
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    ...mapState(['pubfeed']),
  },
  methods: {
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      if (this.filteredColumns.searchKeyword.Contains !== '') {
        array.push(this.filteredColumns.searchKeyword)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    getTableData(ctx, callback) {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.total = response.Total
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        // this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
